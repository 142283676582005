#root {
  height: 100vh !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.headerMenu {
  color: #000000 !important;
}

.ui.menu .item::before {
  display: none !important;
}

.ui.menu:after {
  display: none !important;
}

.headerMenuContainer {
  border: none !important;
  box-shadow: none !important;
}

.appHeader>.item {
  color: #000000 !important;
  cursor: pointer !important;
}

.appHeader {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  display: flex !important;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  height: 60px !important;
  margin: 0 !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 999 !important;
}

.appHeader:not(.appHeader.sideMenu) {
  justify-content: center;
  box-shadow: 0 15px 30px -10px rgb(0 0 0) !important
}

.appHeader.sideMenu {
  align-items: center;
  justify-content: left;
  padding-left: 1rem;
}

.contact {
  display: flex;
  flex-direction: row;
}

.contactDetails,
.contactForm {
  padding: 1rem;
  width: 50%;
}

.divider {
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000
}

.ui.menu.sidebar {
  height: max-content !important;
}

.contactDetails>.ui.grid>.row {
  flex-wrap: nowrap !important;
}

@media (max-width: 850px) {
  .contact {
    flex-direction: column;
  }

  .contactDetails,
  .contactForm {
    padding: 1rem;
    width: 100%;
  }

  .filterContainer {
    margin: 1rem 0 2rem !important;
  }

  .listContainer {
    column-count: 1 !important;
  }

  .listContainerWithYear,
  .groupMembersImageContainer,
  .galleryContainer {
    margin: 0 0 2rem !important;
  }

  .websiteTitle {
    display: none !important;
  }

  .facilitiesDetails {
    align-items: center !important;
    flex-direction: column !important;
  }

  .rgbHeader,
  .homePageHeader {
    white-space: break-spaces !important;
  }

  .position {
    align-items: center;
  }

  .positionContainer::before {
    left: 50% !important;
  }

  .positionSegment {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }

  .positionYear {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    position: absolute !important;
    text-align: center !important;
    top: -0.75rem;
  }

  .positionDetails {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
  }

  .positionYear:after {
    display: none !important;
  }

  .rgbDetailsAndRecognitions {
    flex-direction: column !important;
  }

  .recognitions {
    margin: 3rem 0 0 !important;
    width: 100% !important;
  }

  .publicationsMenu.ui.tabular.fluid.menu {
    display: flex !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
  }

  .headerMenu {
    border-bottom: 1px solid #00000033 !important;
    display: flex !important;
    flex-direction: column !important;
    font-size: 1rem !important;
    justify-content: space-between !important;
    padding: 1rem !important;
  }

  .ui.item.dropdown.item.headerMenu {
    align-items: flex-start !important;
    flex-direction: column !important;
    padding: 0 !important;
  }

  .headerMenuContainer {
    display: flex !important;
    flex-direction: column !important;
  }

  .dropdown.icon {
    left: 90% !important;
    position: absolute !important;
    color: black;
    top: 14px;
  }

  .subMenuContainer {
    position: static !important;
    border: none !important;
    box-shadow: none !important;
  }

  .subMenuContainer>.headerSubMenu:first-child {
    border-top: 1px solid #00000033 !important;
  }

  .ui.menu .ui.dropdown .menu>.headerSubMenu.item:last-child,
  .ui.menu .ui.dropdown .menu>.headerSubMenu.active.item:last-child {
    border-bottom: none !important;
  }

  .ui.menu .ui.dropdown .menu>.item.headerSubMenu {
    padding-left: 5rem !important;
  }

  .selectedPublicationsAndLatestNews {
    flex-direction: column !important;
    align-items: center !important;
  }

  .websiteHeader .ui.header {
    color: #000000 !important;
  }

  .latestNewsAndAnnualReport,
  .selectedPublications {
    max-width: 100% !important;
    margin-bottom: 2rem !important;
  }

  .motionPicturesContainer {
    display: none !important;
  }
}

.appFooter {
  background-color: #000000;
  color: #ffffff;
  display: flex;
  justify-content: center;
  min-height: 175px;
  width: 100%;
}

.footerHeader {
  color: #ffffff !important;
}

.appFooterContaint {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem;
  text-align: center;
}

.pageContainer {
  min-height: calc(100vh - 240px);
}

.ui.ordered.list .list>.item::before,
.ui.ordered.list>.item::before,
ol.ui.list li::before {
  color: #000000 !important;
  content: counters(ordered, ".") "." !important;
  font-weight: bold !important;
  margin-left: -3.25rem !important;
  opacity: 1 !important;
}

.listItem {
  margin: 1rem;
  text-align: justify;
}

.listNumber {
  font-weight: bold;
  left: -2.5rem;
  position: absolute;
  width: 2.5rem;
  text-align: right;
}

.listContainer {
  column-count: 2;
  column-gap: 5rem;
  margin: 0 0 0 5rem;
}

.itemDetails {
  break-inside: avoid-column;
  padding: 1rem;
  position: relative;
}

.contentText {
  border-radius: 10px;
  box-shadow: 0 0 20px 0 #888888;
  line-height: 2rem;
  margin-left: 0;
  padding: 2rem;
  padding-top: 8rem;
  text-align: justify;
  width: 100%;
}

.areasOfInterestImg {
  aspect-ratio: 1/1;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 #888888;
  left: 150px;
  position: absolute;
  top: 0;
  translate: -50% -50%;
  width: 200px;
}

.areasOfInterestTitleContainer {
  align-items: flex-end;
  aspect-ratio: 1/1;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  border-radius: 10px;
  content: "";
  display: flex;
  justify-content: center;
  left: 150px;
  position: absolute;
  text-align: center;
  top: 0;
  translate: -50% -50%;
  width: 200px;
}

.areasOfInterestTitle {
  color: #ffffff !important;
}

.areasOfInterestContent {
  margin-top: 10rem;
  position: relative;
}

.areasOfInterestImgContainer,
.motionPicturesContainer {
  height: 0 !important;
}

.publicationsMenu.ui.tabular.fluid.menu {
  width: 100% !important;
}

.jobOpeningsContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
  width: 100%;
}

.jobOpeningsDetails {
  line-height: 3rem;
}

.jobOpenings {
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 128, 128, 0.5) 50%, rgba(192, 4, 4, 1) 100%);
  margin-top: 5rem;
  width: 100%;
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin: 1rem 6rem 2rem;
}

.filterOptions {
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #888888;
  color: #000000;
  cursor: pointer;
  padding: 0.5rem;
}

.filterOptions.active {
  background-color: #c00404 !important;
  color: #ffffff;
}

.filterOptions:not(.filterOptions.active):hover {
  background-color: #c0040480 !important;
  color: #ffffff;
}

.listContainerWithYear {
  margin: 0 0 0 3rem;
}

.imageDetails {
  box-shadow: 0 0 10px 0 #888888;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 20rem;
}

.galleryImage {
  aspect-ratio: 1 / 1;
  cursor: pointer;
  object-fit: contain;
  width: 100%;
}

.imageTitle {
  align-items: center;
  background: #edf2f6;
  display: flex;
  height: 100%;
  padding: 0.5rem;
  min-height: 4rem;
  word-break: break-word;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
  margin: 5rem 0;
}

.galleryContainer {
  margin: 0 5rem 5rem;
}

.websiteTitle {
  margin: 0 !important;
}

.websiteHeader {
  align-items: center;
  background: #2185d0;
  display: flex;
  height: 5rem;
  justify-content: space-between;
  padding: 0 3rem;
}

.websiteHeader .ui.header {
  color: #ffffff;
}

.websiteLogo {
  aspect-ratio: 2 / 1;
  cursor: pointer;
  width: 9rem;
}

.websiteTitleHeaderMenu {
  color: #000000 !important;
  margin: 0 0 0 2rem !important;
}

.pushable:has(> .visible.sidebar) {
  overflow: hidden !important;
}

.pushable {
  overflow: auto !important;
}

.pushable>.pusher {
  overflow: visible !important;
}

.headerSideIcon {
  cursor: pointer;
}

.facilities {
  border-radius: 10px;
  box-shadow: 0 0 20px 0 #888888;
  position: relative;
}

.facilitiesContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.facilitiesHeader {
  border-bottom: 1px solid #000000 !important;
  margin: 1rem !important;
  padding: 1rem 2rem;
}

.facilitiesDetails {
  align-items: flex-start;
  display: flex;
}

.facilitiesImg {
  aspect-ratio: 1 / 1;
  padding: 1rem;
  width: 15rem;
}

.facilitiesContent {
  padding: 1rem;
  text-align: justify;
  width: 100%;
}

.groupMembersImageDetails {
  background: #edf2f6;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: space-between;
  min-height: 10rem;
  padding: 1.5rem;
}

.groupMembersImageContainer {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 1rem 5rem;
}

.membergGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
}

.researchGrpCat {
  margin: 1rem 0 2rem !important;
}

.card {
  border-radius: 10px;
  box-shadow: 0 0 20px 0 #888888;
  position: relative;
  width: 17rem;
}

.cardDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 50%;
  justify-content: space-between;
  padding: 1rem;
}

.cardImg {
  aspect-ratio: 1 / 1;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
  width: 100%;
}

.cardHolder {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.PhD alumni {
  font-size: 1.5rem;
  line-height: 1.5;
}

.pageHeaderBackground {
  display: flex;
  height: 10rem;
  position: relative;
  width: 100%;
}

.pageHeader {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 1);
  color: #ffffff !important;
  left: 50%;
  margin: 0 !important;
  padding: 0 1rem !important;
  position: absolute;
  top: 50%;
  translate: -50% -50%;
  white-space: nowrap;
}

.pageHeaderImg {
  object-fit: cover;
  width: 100%;
}

.pageSegment {
  margin: 5rem 2rem;
}

.memberField {
  color: #666666;
}

.memberName {
  margin-top: 0.5rem !important;
}

.rgbImageAndDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rgbImage {
  aspect-ratio: 1 / 1;
  max-width: 25rem !important;
  width: 100%;
}

.rgbDetails {
  background: #edf2f6;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
  width: 100%;
  max-width: 25rem;
}

.rgbName {
  margin: 0 !important;
}

.rgbDescription {
  padding: 1rem;
  text-align: justify;
}

.positionSegment {
  position: relative;
  width: max-content;
}

.atGlanceSegment {
  display: flex;
  gap: 4rem;
  position: relative;
}

.atGlance {
  margin: 3rem 0 0;
  max-width: 39%;
}

.atGlanceContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 3rem;
  position: relative;
}

.atGlanceContainer::before {
  background: #f5f3f4;
  border-radius: 3px;
  content: '';
  height: 115%;
  left: 4.1rem;
  position: absolute;
  top: -1rem;
  width: 0.5rem;
}

.positionDetails {
  margin: 0;
}

.atGlanceTitle {
  margin: 0;
}

.positionText {
  margin: 0 0 0.25rem !important;
}

.positionSegment {
  display: flex;
  gap: 5rem;
}

.positionContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  width: max-content;
}

.positionYear {
  min-width: 8rem;
  position: relative;
  text-align: right;
}

.atGlanceYear {
  font-weight: bold;
  position: relative;
}

.atGlanceYear::after {
  background-color: #6597db;
  border: 5px #ffffff solid;
  border-radius: 50%;
  content: '';
  height: 24px;
  left: 3.5rem;
  position: absolute;
  top: -2px;
  width: 24px;
}

.positionContainer::before {
  background: #f5f3f4;
  border-radius: 3px;
  content: '';
  height: 105%;
  left: 10.5rem;
  position: absolute;
  top: -1rem;
  width: 0.5rem;
}

.position {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.positionYear::after {
  background-color: #6fc75b;
  border: 5px #ffffff solid;
  border-radius: 50%;
  content: '';
  height: 24px;
  left: 9.9rem;
  position: absolute;
  top: 0;
  width: 24px;
}

.positionDetailsConatiner {
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  justify-content: space-around;
  margin: 5rem 0;
}

.recognitions {
  width: 50%;
}

.recognitionsName {
  margin-bottom: 1rem;
  text-align: justify;
}

.education {
  margin: 5rem 0;
  width: 100%;
}

.educationDetails,
.selectedPublicationsDetails {
  margin-left: 1rem;
  text-align: justify;
}

.carsolImg {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.carsolContainer {
  height: 25rem;
  position: relative;
  text-align: center;
  width: 100%;
}

.modalRole {
  font-size: 1rem;
  font-weight: normal;
}

.ui.modal>.content>.description {
  display: flex !important;
  flex-direction: column !important;
  gap: 2rem !important;
  width: 100% !important;
}

.viewMore {
  color: #0000EE;
  cursor: pointer;
  width: max-content;
}

.viewMore>u {
  text-decoration-color: #0000EE;
}

.ui.image img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  width: 100% !important;
}

.imageModal {
  align-items: center;
  display: flex;
}

.imageModalContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ui.modal>.galleryModal.image.content {
  justify-content: center !important;
}

.imageModalContainer>.ui.medium.image {
  font-size: 1rem;
  height: auto;
  max-height: 80vh;
  max-width: 80vw;
  width: auto !important;
}

.galleryModalContainer.ui.basic.modal>.close {
  left: 90% !important;
  top: 0 !important;
  translate: -50% -50% !important;
}

.leftRightArrow {
  cursor: pointer;
}

.cardHeader {
  margin: 0 !important;
}

.pageSegment.admin {
  margin: 2rem !important;
}

.homeSegement.admin {
  min-height: 25rem !important;
}

.uploadImage {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.uploadImageLabel {
  margin: 0 !important;
}

.imagePreview {
  width: 25rem;
}

.buttonsGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.rgbDetailsAndRecognitions {
  display: flex;
  margin: 5rem 0 3rem;
  width: 100%;
  gap: 3rem;
  justify-content: space-evenly;
}

.facilityEmail {
  display: flex;
  float: right;
  margin: 1rem 1rem 0;
}

.facilityEmailLabel {
  margin: 0 !important;
}

.richTextEditor {
  width: 100% !important;
}

.rdw-editor-toolbar {
  margin: 0 !important;
}

.editor.rdw-editor-main {
  border: 1px solid #F1F1F1;
  border-top: 0 !important;
  min-height: 10rem;
  padding: 1rem !important;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin: 0 !important;
  padding: 0 !important;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR::before {
  left: -20px !important;
  top: 50% !important;
  translate: -50% -50% !important;
}

.rdw-link-modal-target-option {
  display: none !important;
}

.rdw-link-modal {
  display: flex !important;
  gap: 0.5rem !important;
}

.ui.form input:not([type]) {
  padding: 0.25rem !important;
}

.latestNewsAndAnnualReport,
.selectedPublications {
  max-width: 49%;
}

.latestNews,
.annualReport,
.selectedPublications,
.visitorCount {
  background-color: #edf2f6;
  border-radius: 10px;
  padding: 3rem 3rem;
  height: max-content;
  margin-bottom: 2rem;
}

.latestNewsEvent {
  margin: 2rem 0;
}

.latestNewsTitle {
  color: #000000;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.newsContainer {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.newsYearContainer {
  display: flex;
  gap: 3rem;
  padding: 1rem;
  position: relative;
}

.newsYear {
  font-size: 1.5rem;
  font-weight: bold;
  min-width: 4rem;
  position: relative;
  text-align: right;
}

.newsYear::after {
  background-color: #ffbf13;
  border: 5px #ffffff solid;
  border-radius: 50%;
  content: '';
  height: 24px;
  left: 4.5rem;
  position: absolute;
  top: 0;
  width: 24px;
}

.latestNewsSegment {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.latestNewsSegment::before {
  background: #f5f3f4;
  border-radius: 3px;
  content: '';
  height: 105%;
  left: 6.15rem;
  position: absolute;
  top: -1rem;
  width: 0.5rem;
}

.selectedPublicationsAndLatestNews {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  justify-content: space-evenly;
  margin: 5rem 0rem;
  margin-bottom: 2rem;
}

.subMenuContainer {
  width: 100%;
}

.ui.menu .ui.dropdown .menu>a.item,
.ui.menu .ui.dropdown .menu>a.active.item {
  border-bottom: 1px #00000033 solid !important;
  white-space: break-spaces;
}

.headerMenu {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.divider.text:not(.adminInput .divider.text) {
  padding: 1rem !important;
}

.divider.text {
  border: none !important;
  color: #000000 !important;
}

.ui.inverted.menu .active.item.headerMenu {
  color: #000000 !important;
}

.formGroup {
  display: flex !important;
  gap: 1rem !important;
}

.adminInput {
  width: max-content !important;
}

.adminInput .ui.fluid.input,
.adminInput .ui.fluid.selection.dropdown {
  min-width: 25rem !important;
}

.editContainer {
  margin: 2rem !important;
}

.selectedImagePreview,
.saveBtn {
  margin: 1rem 0 !important;
}

.fieldLabelRequired::after {
  margin: -.2em 0 0 .2em;
  content: '*';
  color: #db2828;
  display: inline-block;
  vertical-align: top;
}

.imageAndDescription {
  display: flex;
}

.adminInputContainer {
  margin: 1rem 0 !important;
}

.adminInputContainer>.equal.width.fields {
  align-items: flex-end !important;
}

.buttonsGroup .divider.text {
  color: #ffffff !important;
  font-weight: 700 !important;
}

.btnWidth {
  min-width: 15rem !important;
  text-align: left !important;
  margin: 0 !important;
}

.ui.dropdown .visible.menu.transition {
  border: 1px solid #96c8da !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.adminFilterDetails {
  min-width: 10rem;
  max-width: max-content;
  text-align: left;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled,
.ui.form .field.disabled>label,
.ui.form .fields.disabled>label {
  opacity: 1 !important;
}

.field.adminInput.disabled .dropdown.icon {
  display: none !important;
}

.addFilterBtn {
  display: flex !important;
  align-items: flex-end !important;
}

.closeIcon {
  cursor: pointer;
}

.profileElementsLabel:not(.profileElementsLabel.recognitions) {
  margin-bottom: 0 !important;
}

.ui.item.dropdown.item.headerMenu:hover .menu.transition.subMenuContainer {
  display: block !important;
  visibility: visible !important;
}

.adminInput.orderDropdown {
  width: 10rem !important;
}

.selectedOrder {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.datePicker {
  width: max-content !important;
}

.ui.form .fields .datePicker .field {
  width: 100% !important;
}

.ui.buttons .button {
  border-radius: 0.28571429rem !important;
}

.adminInput.latestNewsTitle {
  cursor: default !important;
}

.colorBlack {
  color: #000000 !important;
}

.clearBtn,
.addBtn,
.deleteBtn {
  margin: 1rem 0 !important;
}

.rdw-link-decorator-icon {
  display: none !important;
}

.memberDeleteDetail {
  display: flex;
  gap: 1rem;
}

.margin-auto {
  margin: auto !important;
}

.annualReportContainer {
  display: flex;
  gap: 2rem;
}

.projectsHandledDetails {
  position: relative;
  margin-left: 2rem;
}

.motionPictures {
  width: 300px;
  height: 200px;
  border-radius: 10px;
  position: relative;
  top: -100px;
  left: 300px;
  box-shadow: 0 0 20px 0 #888888;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftRightArrowContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #808080;
}

.relatedImages {
  position: absolute !important;
  height: 100%;
  padding: 0.5rem;
  cursor: pointer;
}

.relatedPublications {
  width: max-content;
  border: 1px #00000080 solid;
  padding: 0 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.relatedPublicationsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-2 {
  margin-bottom: 2rem;
}

.reorder {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.requestsFilter {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.adminInputContainer.requests {
  margin: 0 !important;
  width: 15rem !important;
}

.requestTable {
  margin-top: 1rem !important;
  text-align: center;
}

.toolBar {
  display: flex;
  justify-content: space-between;
}

.m-0 {
  margin: 0 !important;
}

.redFont {
  color: red !important;
}

.m-75 {
  margin: 0 0.5rem;
}

.adminInputContainer.adminForm>.equal.width.fields {
  align-items: flex-start !important;
}

.text-align-center {
  text-align: center;
}

.scrollingText {
  padding: 0.5rem 0;
  color: white;
  width: 30rem;
  margin: auto;
  position: absolute;
  bottom: -15%;
  opacity: 999;
  left: 50%;
  translate: -50% -50%;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 1);
}

.multipleImages {
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 1rem 0 0;
}

.singleImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25rem;
}

.mulipleImageUpload {
  margin: 1rem 0;
}

.imageCloseIcon {
  cursor: pointer;
  box-shadow: 0 0px 10px 5px white;
  width: max-content !important;
  border-radius: 50%;
  background: white;
  margin: 0 2rem !important;
}

.position-relative {
  position: relative;
}

.loginPage {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #141e30, #243b55);
}

.loginPageConatiner {
  width: 360px;
  margin-top: 50px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.loginHeader {
  background-color: #007bff;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.loginHeader h2 {
  margin: 0;
  font-size: 24px;
}

.loginContainer {
  padding: 20px;
}

.loginInputs {
  margin-bottom: 15px;
  width: 100%;
}

.loginInputs label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

.loginInputs input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.loginInputs input[type="submit"] {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.loginInputs .ui.input {
  width: 100%;
}

.ui.segment.pushable {
  margin: 0 !important;
}

.fileUpload {
  margin-bottom: 0 !important;
}

.filePreview {
  margin: 1rem 0 0;
}

.postionAndProfileBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.color-white {
  color: #ffffff !important;
}

.poweredby {
  display: flex;
  white-space: nowrap;
  gap: 0.25rem;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.crusoftLogo {
  width: 7rem;
  cursor: pointer;
}

.ScrollTop {
  position: fixed;
  bottom: 20px;
  background-color: green;
  right: 5%;
  height: 3rem;
  width: 3rem;
  margin: 0;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.visitorCountContainer {
  font-size: 3rem;
  margin-top: 2rem;
  font-weight: bold;
  display: flex;
  gap: 1rem;
}